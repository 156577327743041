.layout-wrapper {
  display: flex;
  align-items: stretch;
}

.layout-content {
  width: 100%;
  min-width: calc(100% - 200px);
  max-width: calc(100% - 200px);

  &.full {
    min-width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .layout-content {
    width: 100%;
    min-width: calc(100% - 90px);
    max-width: calc(100% - 90px);
  }
}
