@mixin layout-styles($name, $value) {
  .#{$name} {
    #{$name}: $value !important;
  }
  .#{$name}-top {
    #{$name}-top: $value !important;
  }
  .#{$name}-bottom {
    #{$name}-bottom: $value !important;
  }
  .#{$name}-left {
    #{$name}-left: $value !important;
  }
  .#{$name}-right {
    #{$name}-right: $value !important;
  }
  .#{$name}-vertical {
    #{$name}-top: $value !important;
    #{$name}-bottom: $value !important;
  }
  .#{$name}-horizontal {
    #{$name}-left: $value !important;
    #{$name}-right: $value !important;
  }
  .no-#{$name} {
    #{$name}: 0 !important;
  }
  .no-#{$name}-top {
    #{$name}-top: 0 !important;
  }
  .no-#{$name}-bottom {
    #{$name}-bottom: 0 !important;
  }
  .no-#{$name}-left {
    #{$name}-left: 0 !important;
  }
  .no-#{$name}-right {
    #{$name}-right: 0 !important;
  }
  .no-#{$name}-vertical {
    #{$name}-top: 0 !important;
    #{$name}-bottom: 0 !important;
  }
  .no-#{$name}-horizontal {
    #{$name}-left: 0 !important;
    #{$name}-right: 0 !important;
  }

}

@mixin width-styles {
  .width-10 {
    width: 10%;
  }
  .width-20 {
    width: 20%;
  }
  .width-33 {
    width: 33.33%;
  }
  .width-50 {
    width: 50%;
  }
  .width-66 {
    width: 66.66%;
  }
  .width-80 {
    width: 80%;
  }
  .width-90 {
    width: 90%;
  }
  .width-100 {
    width: 100%;
  }
}

@mixin scrollbar {
  &:-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
  }
}

@mixin rotate {
  .rotate-90 {
    transform: rotate(90deg);
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  .rotate-270 {
    transform: rotate(270deg);
  }
}