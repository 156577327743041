/* You can add global styles to this file, and also import other style files */
@import '_variables';

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/bootstrap';
@import '~@progress/kendo-theme-bootstrap/scss/all';
@import '~ngx-toastr/toastr-bs4-alert';
@import '_foundation';
@import 'kendo.tweaks';
@import 'read-only';

* {
  font-family: $font-family;
}

@include layout-styles('padding', 16px);
@include layout-styles('margin', 16px);
@include width-styles();
@include rotate();

.btn-primary {
  background-color: $brand-primary !important;
}

.tooltip{
  z-index: 99999 !important;
}

.form-section {
  padding: 25px;
  background-color: $brand-subtle;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
}

.form-tip {
  color: lighten($brand-secondary, 40%);
  font-weight: normal;
  margin-left: 6px;
}

.col {
  margin-bottom: 20px;
}

.fa {
  font-size: 20px;
}

.required-star {
  &:after {
    content: ' *';
    color: $brand-danger;
    vertical-align: top;
  }
}

.panel-help {
  border: 1px solid $brand-success;
  background-color: lighten($brand-success, 35%);
  padding: 16px;
  .title {
    font-size: 20px;
    margin-bottom: 20px;
    .icon {
      float: right;
    }
  }
}

.scrollbar {
  @include scrollbar();
}

.pull-right-buttons {
  display: inline;
}

.pull-right-buttons-child {
  margin-right: 20px;
}

.pull-right-single {
  float: right !important;
  margin-left: 4px !important;
}

.pull-center-single {
  margin-left: 4px !important;
}

.child-content {
  margin-top: 20px;
}

@media (max-width: 790px) {
  .pull-right-buttons {
    display: block;
    margin-top: 10px;
  }
  .pull-right-single {
    float: inherit !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.header-label {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.checkbox-label {
  font-weight: bold;
}

.vme .text {
  border: none;
  outline: none;
  background: none;
  opacity: 1;
}

.simple-notification {
  &.warn {
    .icon svg {
      fill: #444;
    }
  }
  .icon {
    $size: 45px;
    width: $size !important;
    height: $size !important;
  }
}

.k-form-field {
  padding-right: 8px;
  padding-left: 8px;
}

.k-form .k-checkbox-label, .k-form .k-radio-label, .k-form-inline .k-checkbox-label, .k-form-inline .k-radio-label {
  padding: 8px;
}

.k-form .k-radio-label, .k-form-inline .k-radio-label {
  border-radius: 50% !important;
}

.k-form-text-value {
  padding: 5px;
}

body.modal-open {
  overflow: hidden;
}

.actions-menu {
  position: relative;
  left: -20px;
  top: 5px;
  width: 250px !important;;
}

.form-group.read-only input {
  color: #444 !important;
}

.form-group.total input {
  font-weight: bold !important;
}
