.read-only .k-state-disabled,
.read-only :disabled,
.read-only [disabled] {
  opacity: 1.0 !important;
}

.read-only .k-button[disabled],
.read-only .k-button.k-state-disabled:hover,
.read-only .k-button.k-state-disabled:focus {
  opacity: 0.6 !important;
}

.read-only .k-combobox .k-icon,
.read-only .k-datepicker .k-icon,
.read-only .k-datepicker .k-select,
.read-only .k-dropdown .k-icon,
.read-only .k-numerictextbox .k-icon,
.read-only .k-multiselect .k-icon {
  display: none !important;
}

.read-only .k-combobox,
.read-only .k-dropdown,
.read-only .k-textbox {
  font-weight: normal;
}

.read-only textarea {
  background: white;
}

.read-only .form-control:disabled,
.read-only .form-control[readonly],
.read-only .form-control.k-state-disabled,
.read-only .form-control[ng-reflect-disabled=true],
.read-only .k-dropdown-wrap.k-state-disabled,
.read-only .k-picker-wrap.k-state-disabled,
.read-only .k-picker-wrap.k-state-disabled .k-dateinput-wrap {
  background: white !important;
}

.read-only .k-radio:not(:checked) + .k-radio-label {
  display: none !important;
}

.read-only .k-checkbox + .k-checkbox-label,
.read-only .k-radio + .k-radio-label {
  font-weight: normal;
  opacity: 1.0 !important;
}

.read-only .form-tip {
  display: none !important;
}

.read-only .required-star:after {
  content: '';
}

.read-only ::-webkit-input-placeholder { /* WebKit browsers */
  color: transparent;
}

.read-only :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: transparent;
}

.read-only ::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: transparent;
}

.read-only :-ms-input-placeholder { /* Internet Explorer 10+ */
  color: transparent;
}

.read-only textarea::-webkit-input-placeholder { /* WebKit browsers */
  color: transparent;
}

.read-only textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: transparent;
}

.read-only textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: transparent;
}

.read-only textarea:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: transparent;
}
