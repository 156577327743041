@import 'variables';

.k-grid {
  .k-form {
    padding: 0 !important;
  }

  .k-form-field {
    margin-bottom: 0 !important;
  }

  .k-grid-header-wrap {
    .k-checkbox-label,
    .k-radio-label {
      padding-left: 0 !important;
      padding-right: calc( 6px + 0.5rem) !important;
    }
  }

  .k-grid-header-multiline {
    white-space: normal !important;
  }

  .k-checkbox-label,
  .k-radio-label {
    padding-left: 0 !important;
    padding-right: calc( 13px + 0.5rem) !important;
  }
}

table tbody > tr {
  height: 36px !important;
  font-size: 13px;

  td {
    padding: 0 .245rem !important;
  }
}

.k-popup .k-popup {
  border: none;
  background-color:whitesmoke;
}

.k-list {
  color: $brand-primary !important;
}

.k-list-container .k-list .k-item {
  padding-right: 25px;
}

.k-button {
  background-color: whitesmoke !important;
  color: darkgray !important;
  padding: 3px 5px 3px 5px !important;
}

.k-dropdown-button.btn-sm {
  button {
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
  }
  span {
    font-size: 0.875rem !important;
  }
}

.k-dropdown {
  .k-dropdown-wrap {
    color: #495057 !important;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
  }
}

.k-calendar {
  //font-size: 13px;
}

.k-filter-menu-container {
  .k-action-buttons {
    margin-bottom: -1rem;
    .k-button {
      line-height: 2rem;
    }
  }
}

.k-datepicker {
  display: block;
}

.form-control:disabled,
.form-control[readonly],
.form-control.k-state-disabled,
.form-control[ng-reflect-disabled=true],
.k-dropdown-wrap.k-state-disabled,
.k-picker-wrap.k-state-disabled,
.k-picker-wrap.k-state-disabled .k-dateinput-wrap {
  background-color: #eef1f4 !important;
  opacity: 1;
}

.navbar-collapse {
  margin-left: -20px;
}

.k-checkbox-label,
.k-radio-label {
  padding-left: 0 !important;
  padding-right: calc( 16px + 0.5rem) !important;
}

.k-checkbox-label::before,
.k-checkbox-label::after,
.k-radio-label::before,
.k-radio-label::after {
  right: 0 !important;
  left: auto !important;
}

.k-upload {
  margin-bottom: 10px;
  border: none !important;
}

.k-upload-button {
  border: none !important;
  padding: 0.375rem 0.75rem !important;
  color: #fff !important;
  background-color: #2e2046 !important;
}

.k-list {
  .k-item {
    span {
      margin-right: 10px;
    }
  }
}
